<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" service="/sys/sysNotificTemplate" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  this.$refs.xGrid.insertEvent();
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "title",
            title: "模版标题",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入模版标题"
              }
            }
          },
          {
            field: "type",
            title: "模版类型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "templateType",
              props: {
                maxlength: 1,
                placeholder: "请输入模版类型"
              }
            }
          },
          {
            field: "path",
            title: "跳转路径",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入跳转路径"
              }
            }
          },
          {
            field: "code",
            title: "模板编码",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入模板编码"
              }
            }
          },
          {
            field: "content",
            title: "模版内容",
            minWidth: 300,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入模版内容"
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row)
                }
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
            }
          }
        ],
        exportConfig: {},
        importConfig: {},
        editConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 120,
        items: [{
            field: "title",
            title: "模版标题",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入模版标题"
              }
            }
          },
          {
            field: "type",
            title: "模版类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "templateType",
              props: {
                maxlength: 1,
                placeholder: "请输入模版类型"
              }
            }
          },
          {
            field: "code",
            title: "模板编码",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入模板编码"
              }
            }
          },
          {
            field: "path",
            title: "跳转路径",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入跳转路径"
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态"
              }
            }
          },
          {
            field: "content",
            title: "模版内容",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入模版内容"
              }
            }
          },
          {
            field: "remark",
            title: "描述",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入描述内容"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          title: [{
            required: true,
            message: "请输入模版标题"
          }],
          type: [{
            required: true,
            message: "请选择模版类型"
          }],
          code: [{
            required: true,
            message: "请输入模板编码"
          }],
          enabled: [
            { required: true, message: '请输选择状态' },
          ],
          content: [{
            required: true,
            message: "请输入模版内容"
          }]
        },
        // 新增功能字段初始化
        data: {
          enabled: "1",
          title: "",
          type: "",
          path: "",
          code: "",
          content: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "title",
            title: "模版标题",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入模版标题"
              }
            }
          },
          {
            field: "type",
            title: "模版类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "templateType",
              props: {
                maxlength: 1,
                placeholder: "请输入模版类型"
              }
            }
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          title: "",
          type: "",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
